/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-item {
  font-size: 1em !important;
}

ion-menu {
  ion-header {
    padding: 0 !important;
  }
  tcc-mono-menu-item {
    ion-item:first-child {
      // margin: 6px 0px;
    }
  }
}

ion-toolbar {
  ion-text {
    padding: 16px;
  }
}

ion-note {
  font-size: 0.8em;
}

// ion-label.form-label {
//   font-size: 1.4em!important;
// }

// .native-input {
//   font-size: 1.1em!important;
// }

// .native-textarea {
//   font-size: 1.1em!important;
// }
